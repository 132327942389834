/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import SEO from "@components/seo"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import Layout from "@components/Layouts"
import Container from "@components/Container"
import CalloutTextBlocks from "@components/CalloutTextBlocks"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import Section from "@components/Section"
import ImageTextBlocksList from "@components/ImageTextBlocksList"
import { rem } from "@src/theme"
import { formatMarkdownToHtml } from "@helpers"
import MarkdownBody from "@modules/MarkdownBody"
import ImageGallery from "@components/ImageGallery"
import CallToAction from "@components/CallToAction"
import QuickLinks from "@components/QuickLinks"
import IconThreeUp from "@components/IconThreeUp"
import TextCarousel from "@components/TextCarousel"
import styled from "styled-components"
import BannerCta from "@components/BannerCta"
import TestimonialCarousel from "@components/TestimonialCarousel"
import Accordion from "@components/Accordion"
import Spacer from "@components/Spacers"
import { DonateSection } from "@components/ImpactFooter"
import PartnerLogos from "@components/PartnerLogos"

export const ContentPageTemplate = ({ data }) => {
  const { frontmatter } = data.pageData
  const {
    header,
    banner,
    ogimage,
    ogdescription,
    threeColumnTextBlock,
    imageGallery,
    imageTextBlock,
    ctaBlock,
    quickLinks,
    threeUpBlock,
    carouselStats,
    smallCta,
    carousel,
    accordion,
    partnerLogos,
    ogTitle,
  } = frontmatter

  console.info("Content", frontmatter)

  return (
    <Layout>
      <SEO
        title={ogTitle || header?.heading}
        image={ogimage}
        ogdescription={ogdescription}
      />
      {banner && (
        <MarkdownBody
          html={formatMarkdownToHtml(banner)}
          sx={{
            bg: "turquoise",
            color: "darkPurple",
            textAlign: "center",
            py: rem(25),

            "h1,h2,h3,h4,h5": {
              my: 0,
            },
          }}
        />
      )}
      {header && header.image ? (
        <TwoColumnSection
          col1={
            <div>
              <ColumnBlock
                styles={{
                  bg: "purple",
                  color: "pink",
                }}
              >
                <CTAHeadingBlock heading={header.heading} />
              </ColumnBlock>
              <ColumnBlock
                styles={{
                  bg: "pink",
                  color: "darkPurple",
                }}
              >
                <CTAHeadingBlock
                  heading={header.subheading}
                  body={header.subtext}
                  buttonLabel={header.ctaButton?.label}
                  buttonLink={header.ctaButton?.link}
                  smallHeading
                />
              </ColumnBlock>
            </div>
          }
          col2={<ColumnImageBlock image={header.image} />}
        />
      ) : (
        <TwoColumnSection
          col1={
            <ColumnBlock
              styles={{
                bg: "purple",
                color: "pink",
              }}
            >
              <CTAHeadingBlock heading={header.heading} />
            </ColumnBlock>
          }
          col2={
            <ColumnBlock
              styles={{
                bg: "pink",
                color: "darkPurple",
              }}
            >
              <CTAHeadingBlock
                heading={header.subheading}
                body={header.subtext}
                buttonLabel={header?.ctaButton?.label}
                buttonLink={header?.ctaButton?.link}
                smallHeading
              />
            </ColumnBlock>
          }
        />
      )}
      {threeUpBlock && threeUpBlock?.columns?.length > 0 && (
        <IconThreeUp
          textBlocks={threeUpBlock.columns.map(item => item.text)}
          heading={threeUpBlock.heading}
        />
      )}
      {imageGallery &&
        Object.values(imageGallery).filter(value => value !== "").length >
          0 && <ImageGallery {...imageGallery} />}
      {threeColumnTextBlock &&
        threeColumnTextBlock.map(row => (
          <Container>
            <Section>
              <CalloutTextBlocks blocks={row.columns} heading={row.heading} />
            </Section>
          </Container>
        ))}
      {imageTextBlock &&
        imageTextBlock?.rows?.length > 0 &&
        (imageTextBlock.gradientBackground ? (
          <GradientWrapper>
            <ImageTextBlocksList blocks={imageTextBlock.rows} />
          </GradientWrapper>
        ) : (
          <ImageTextBlocksList blocks={imageTextBlock.rows} />
        ))}
      {carousel && carousel.length > 0 && (
        <Section>
          <TestimonialCarousel testimonials={carousel} />
        </Section>
      )}
      {carouselStats && carouselStats.length > 0 && (
        <Section>
          <TextCarousel blocks={carouselStats} />
        </Section>
      )}
      {quickLinks && (
        <QuickLinks
          heading={quickLinks.heading}
          quickLinks={quickLinks.links}
        />
      )}
      <CallToAction {...ctaBlock} />
      {partnerLogos && <PartnerLogos {...partnerLogos} />}
      {smallCta && smallCta.heading && (
        <BannerCta
          heading={smallCta.heading}
          text={smallCta.text}
          imageFit={smallCta.imageFit}
          cta={
            smallCta?.ctaButton && {
              url: smallCta?.ctaButton?.link,
              text: smallCta?.ctaButton?.label,
            }
          }
          img={{
            src: smallCta.image,
            alt: smallCta.heading,
          }}
          backgroundImg={smallCta.backgroundImg}
        />
      )}

      {accordion && accordion?.accordionItems?.length > 0 && (
        <>
          <Spacer.Medium />
          <Accordion
            heading={accordion.heading}
            items={accordion.accordionItems}
          />
          <Spacer.Medium />
        </>
      )}
      <DonateSection />
    </Layout>
  )
}

const GradientWrapper = styled.div`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(221, 243, 255, 0) 0.01%,
      rgba(14, 125, 185, 0.5) 100%
    ),
    #ddf3ff;
`

export default ContentPageTemplate
